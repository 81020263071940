import React from 'react';

import PageHeader from 'components/shared/PageHeader';

import ContactForm from 'components/pages/contact/ContactForm';
import SEO from 'components/shared/SEO';

const Contact = ({ location }) => {
   const headerText = [
      'Napisz ',
      <strong>lub zadzwoń</strong>,
   ];

   return (
      <>
         <SEO title="Wypróbuj" />
         <PageHeader headerText={headerText}>
            <p>
               Wypełnij formularz lub zadzwoń jeśli chcesz wypróbować nasz produkt. <br/>
                Odezwiemy się do Ciebie w następnym dniu roboczym.
            </p>
         </PageHeader>

         <ContactForm selectedPackage={location.state?.package} />
      </>
   );
};

export default Contact;
